<template>
  <v-sheet class="pa-2">
    <v-expansion-panels
        v-model="activePanels"
        multiple
        popout>
      <v-expansion-panel
          v-for="message in thread"
          :key="message.id"
          class="my-1">
        <v-expansion-panel-header v-slot="{open}">
          <v-sheet
              v-if="!open"
              key="0"
              class="mr-2 d-flex justify-space-between text-body-2">
            <v-sheet>
              {{ message.from.kontoId === user.personIdentifier ? fullName : (message.from.kontoId !== null ? message.from.kontoId : message.from.receiver) }}
            </v-sheet>
            <v-sheet>
              {{ $date(message.created).format('lll') }}
            </v-sheet>
          </v-sheet>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <message-single-view :message="message"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-sheet>
</template>

<script>
import {mapGetters} from 'vuex';
import MessageSingleView from './MessageSingleView';

export default {
  name: 'MessageThreadView',
  props: {thread: Array},
  components: {MessageSingleView},
  computed: {
    ...mapGetters(['user', 'fullName']),
    activePanels() {
      return [this.lastMessageIndex]
    },
    lastMessageIndex() {
      return this.thread.length - 1
    }
  }
};
</script>

<style scoped>
.v-expansion-panel {
  padding: 0;
}

.v-expansion-panel-header--active {
  min-height: 0 !important;
  padding: 12px 8px 0;
}
</style>
