<!-- only for sachbearbeiter view -->
<!-- contains info about whether the notification message to user's own portal is successfully sent -->
<template>
  <v-sheet>
    <v-divider/>
    <v-card-text v-if="notificationInfo.status === 'pending'">
      <v-sheet class="orange--text">
        {{ $t('messageModal.notificationInfo.messagePending') }}
      </v-sheet>
    </v-card-text>
    <v-card-text v-else-if="notificationInfo.status === 'sent'">
      <v-sheet class="green--text">
        {{ $t('messageModal.notificationInfo.messageSent') }}
      </v-sheet>
      <v-sheet
          v-if="notificationInfo.resentSuccessTime"
          class="grey--text text--darken-2">
        {{ $t('messageModal.notificationInfo.resentSuccessTime') }}:
        {{ formattedResentSuccessTime }}
      </v-sheet>
    </v-card-text>
    <v-card-text v-else-if="notificationInfo.status === 'failed'">
      <v-sheet class="red--text">
        {{ $t('messageModal.notificationInfo.messageFailed') }}
        <v-btn
            class="text-subtitle-2"
            color="error"
            text
            @click="toggleFailureDetails">
          {{ $t('messageModal.notificationInfo.details') }}
          <v-icon small>{{ buttonIcon }}</v-icon>
        </v-btn>
      </v-sheet>
      <v-expand-transition>
        <v-sheet v-show="showFailureDetails">
          <v-list>
            <v-list-item
                v-for="info in failureDetails"
                :key="info.title"
                class="align-start">
              <v-sheet
                  class="mr-2 grey--text text--darken-2"
                  min-width="150px">
                {{ info.title }}
              </v-sheet>
              <v-sheet class="text-break grey--text text--darken-2">
                {{ info.value }}
              </v-sheet>
            </v-list-item>
          </v-list>
          <v-sheet class="d-flex flex-column align-end">
            <v-btn
                :disabled="resendAttempted"
                class="text-subtitle-2"
                color="blue-grey darken-2 white--text"
                @click="resendNotificationMessage(messageId)">
              {{ $t('messageModal.notificationInfo.resend') }}
            </v-btn>
          </v-sheet>
        </v-sheet>
      </v-expand-transition>
    </v-card-text>
  </v-sheet>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'MessageNotificationInfo',
  props: {messageId: String, notificationInfo: Object},
  data() {
    return {
      showFailureDetails: false,
      resendAttempted: false
    };
  },
  computed: {
    ...mapGetters(['configData']),
    formattedResentSuccessTime() {
      return this.$date(this.notificationInfo.resentSuccessTime).format('lll');
    },
    buttonIcon() {
      if (this.showFailureDetails) {
        return 'mdi-chevron-up';
      } else {
        return 'mdi-chevron-down';
      }
    },
    failureDetails() {
      return [
        {
          title: this.$t('messageModal.notificationInfo.failCount'),
          value: this.notificationInfo.failedAttempts.length
        },
        {
          title: this.$t('messageModal.notificationInfo.lastFailed'),
          value: this.$date(this.notificationInfo.lastFailedTime).format('lll')
        },
        {
          title: this.$t('messageModal.notificationInfo.cause'),
          value: this.notificationInfo.failedAttempts[0].errorMessage
        }
      ];
    }
  },
  methods: {
    ...mapActions(['closeMessageModal', 'setNotification']),
    toggleFailureDetails() {
      this.showFailureDetails = !this.showFailureDetails;
    },
    resendNotificationMessage(messageId) {
      const {messagesURL} = this.configData;
      axios.get(`${messagesURL}/send/${messageId}/fink-as4`, {withCredentials: true})
      .then(() => {
        this.resendAttempted = true;
        const newNotification = {
          type: 'success',
          textKey: 'resendAttempted',
          show: true
        };
        this.setNotification(newNotification);
        this.closeMessageModal();
      })
      .catch(() => {
        this.resendAttempted = false;
      });
    }
  }
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}

.v-list-item {
  min-height: 25px;
}
</style>
