import { render, staticRenderFns } from "./MessageAttachments.vue?vue&type=template&id=ee3e2ff2&scoped=true"
import script from "./MessageAttachments.vue?vue&type=script&lang=js"
export * from "./MessageAttachments.vue?vue&type=script&lang=js"
import style0 from "./MessageAttachments.vue?vue&type=style&index=0&id=ee3e2ff2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee3e2ff2",
  null
  
)

export default component.exports