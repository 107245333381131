<template>
  <v-container>
    <v-tabs
        v-if="isRemoteUser"
        v-model="activeMailboxTab"
        :color="portalData.color.bg"
        centered
        class="mb-5">
      <v-tab
          v-for="tabName in mailboxTabs"
          :key="tabName"
          :href="'#' + tabName">
        {{ $t(`mailbox.tabs.${tabName}`) }}
      </v-tab>
    </v-tabs>
    <v-card
        class="mx-auto"
        max-width="950"
        min-height="785">
      <v-card-title
          :class="portalData.color.bg"
          class="white--text elevation-4">
        <span>
          {{ $t('mailbox.messagesTitle') }}
        </span>
        <v-spacer/>
      </v-card-title>
      <v-tabs-items v-model="activeMailboxTab">
        <v-tab-item value="user">
          <v-card-actions v-show="userInboxMessages.length">
            <v-spacer/>
            <v-btn
                :color="portalData.color.bg"
                class="my-1"
                elevation="4"
                small
                text
                @click="openConfirmationModal">
              {{ $t('mailbox.deleteAllMessages') }}
            </v-btn>
          </v-card-actions>
          <mailbox-item-list
              :itemList="filterItemsByPage(userInboxMessages)"
              unreadCheckEnabled
              @openItem="openMessage"/>
        </v-tab-item>
        <v-tab-item value="service">
          <div>
            <v-card-actions v-show="serviceThreadMessages.length">
              <v-spacer/>
              <v-btn
                  :color="portalData.color.bg"
                  class="my-1"
                  elevation="4"
                  small
                  text
                  @click="openConfirmationModal">
                {{ $t('mailbox.deleteAllMessages') }}
              </v-btn>
            </v-card-actions>
            <mailbox-item-list
                :itemList="filterItemsByPage(serviceThreadMessages)"
                @openItem="openThread"/>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <v-pagination
          v-model="activePage"
          :color="portalData.color.bg"
          :length="pageCount"
          class="pagination-position"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"/>
    </v-card>
    <message-modal
        v-if="messageModalContent"
        :activeMailboxTab="activeMailboxTab"
        @triggerReadNotification="openNotificationModal"
        @triggerRefresh="refreshMessageList"/>
    <notification-modal
        :showNotificationModal="showNotificationModal"
        actions
        notificationKey="messageRead"
        @closeModal="closeNotificationModal"/>
    <confirmation-modal
        :showConfirmationModal="showConfirmationModal"
        textKey="deleteAllMessages"
        @closeModal="closeConfirmationModal"/>
  </v-container>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import MailboxItemList from '../MailboxItemList';
import MessageModal from '../MessageModal';
import ConfirmationModal from '../ConfirmationModal';
import NotificationModal from "../NotificationModal";

export default {
  name: 'Mailbox',
  components: {NotificationModal, MailboxItemList, MessageModal, ConfirmationModal},
  props: {pageKey: String},
  metaInfo() {
    return {
      title: this.$t(`documentTitle.mailbox`)
    };
  },
  data() {
    return {
      mailboxTabs: ['user', 'service'],
      activeMailboxTab: 'user',
      activePage: 1,
      itemsPerPage: 8,
      userInboxMessages: [],
      serviceInboxMessages: [],
      openedMessages: [],
      showConfirmationModal: false,
      showNotificationModal: false,
    };
  },
  watch: {
    activeMailboxTab() {
      this.activePage = 1;

      if (this.activeMailboxTab === 'user') {
        this.getUserInboxMessages();
      } else {
        this.getServiceInboxMessages();
      }
    },
  },
  computed: {
    ...mapGetters(['portalData', 'configData', 'user', 'isRemoteUser', 'messageModalContent']),
    pageCount() {
      if (this.activeMailboxTab === 'user') {
        return Math.ceil(this.userInboxMessages.length / this.itemsPerPage);
      } else if (this.activeMailboxTab === 'service') {
        return Math.ceil(this.serviceThreadMessages.length / this.itemsPerPage);
      }
      return 1;
    },
    serviceThreadMessages() {
      return this.serviceInboxMessages.filter(message => !message.replyTo);
    }
  },
  methods: {
    ...mapActions(['setTipPath', 'setNotification', 'openMessageModal', 'closeMessageModal']),
    filterItemsByPage(itemList) {
      return itemList.slice(
          (this.activePage - 1) * this.itemsPerPage,
          this.activePage * this.itemsPerPage
      );
    },
    async openMessage(messageId) {
      this.openMessageModal(await this.getMessageById(messageId));
    },
    async openThread(threadId) {
      this.openMessageModal(await this.getThreadById(threadId));
    },
    async refreshMessageList(messageId) {
      await this.getUserInboxMessages();
      this.openedMessages.find(message => {
        if (message.id === messageId) {
          message.read = true
        }
      });
    },
    openNotificationModal() {
      this.showNotificationModal = true;
    },
    async closeNotificationModal() {
      await this.refreshMessageList(this.messageModalContent.id);
      this.showNotificationModal = false;
      this.closeMessageModal();
    },
    async getUserInboxMessages() {
      const {messagesURL} = this.configData;
      const {personIdentifier, postfachReference} = this.user;
      const userPortal = postfachReference.split('//')[0];

      try {
        const response = await axios.get(
            `${messagesURL}/${personIdentifier}/${userPortal}`,
            {withCredentials: true}
        );
        this.userInboxMessages = response.data.result;
      } catch {
        const newNotification = {
          type: 'error',
          textKey: 'getMessagesError',
          show: true
        };

        this.setNotification(newNotification);
      }
    },
    async getServiceInboxMessages() {
      const {messagesURL} = this.configData;

      try {
        const response = await axios.get(
            `${messagesURL}/service/inbox`,
            {withCredentials: true}
        );
        this.serviceInboxMessages = response.data.result;
      } catch {
        const newNotification = {
          type: 'error',
          textKey: 'getServiceMessagesError',
          show: true
        };
        this.setNotification(newNotification);
      }
    },
    async getMessageById(messageId) {
      let message = this.openedMessages.find(message => message.id === messageId);
      if (message) {
        return message;
      }

      const isService = this.activeMailboxTab === 'service';

      const getMessageURL = new URL(`${this.configData.messagesURL}/${messageId}`)
      getMessageURL.searchParams.append('isServiceRequest', isService)

      const response = await axios.get(getMessageURL, {withCredentials: true});
      message = response.data.result;
      this.openedMessages.push(message);

      return message;
    },
    async getThreadById(threadId) {
      const {messagesURL} = this.configData;

      const response = await axios.get(
          `${messagesURL}/thread/${threadId}`,
          {withCredentials: true}
      );
      return response.data.result;
    },
    deleteAllMessages() {
      const {messagesURL} = this.configData;

      axios.delete(`${messagesURL}/all`, {withCredentials: true})
      .then(() => {
        this.userInboxMessages = [];
        this.serviceInboxMessages = [];
      })
      .catch(() => {
        const newNotification = {
          type: 'error',
          textKey: `deleteMessagesError`,
          show: true
        };
        this.setNotification(newNotification);
      });
    },
    openConfirmationModal() {
      this.showConfirmationModal = true;
    },
    closeConfirmationModal(isConfirmed) {
      if (isConfirmed) {
        this.deleteAllMessages();
      }

      this.showConfirmationModal = false;
    }
  },
  async created() {
    this.closeMessageModal();
    await this.getUserInboxMessages();
    if (this.isRemoteUser) {
      await this.getServiceInboxMessages();
    }

    const {messageId, loa} = this.$route.params;

    if (messageId && loa) {
      this.$router.replace({name: 'Mailbox'});

      const message = this.userInboxMessages.find(message => message.id === messageId);

      if (message) {
        if (!message.hidden) {
          this.openMessage(message.id);
        } else {
          const newNotification = {
            type: 'error',
            textKey: `unauthorizedLOA.${message.loa}`,
            show: true
          };
          this.setNotification(newNotification);
        }
      } else {
        const newNotification = {
          type: 'error',
          textKey: 'openMessageError',
          show: true
        };
        this.setNotification(newNotification);
      }
    }
  },
  mounted() {
    this.setTipPath({pageKey: this.pageKey, tipKey: 'general'});
  }
};
</script>

<style scoped>
.pagination-position {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.v-input--selection-controls {
  margin: 0;
  padding: 0;
}
</style>
