<template>
  <v-list
      v-if="itemList.length"
      class="mb-2 py-0"
      two-line>
    <v-sheet
        v-for="item in itemList"
        :key="item.id">
      <v-list-item
          :disabled="item.hidden"
          :ripple="{ class: portalData.color.text }"
          class="py-1"
          @click="handleClick(item.id)">
        <v-sheet
            v-if="item.hidden"
            class="text-body-2 grey--text text--darken-2">
          {{ $t('mailbox.unauthorizedLOA') }} ({{ $t(`loa.${item.loa}`) }})
        </v-sheet>
        <v-sheet v-else>
          <v-sheet
              :class="(unreadCheckEnabled && !item.read) && 'font-weight-bold'"
              class="text-wrap text-break">
            {{ formatFromInfo(item.from) }}
          </v-sheet>
          <v-sheet
              :class="(unreadCheckEnabled && !item.read) && 'font-weight-bold'"
              class="mt-1 text-body-2 grey--text text--darken-2">
            {{ item.subject || "---" }}
          </v-sheet>
        </v-sheet>
        <v-spacer/>
        <v-sheet
            :class="(unreadCheckEnabled && !item.read) && 'font-weight-bold'"
            class="text-end text-body-2"
            min-width="30%">
          {{ $date(item.created).format('lll') }}
        </v-sheet>
      </v-list-item>
      <v-divider/>
    </v-sheet>
  </v-list>
  <v-card-text
      v-else
      class="grey--text text--darken-2">
    {{ $t('mailbox.noMessages') }}
  </v-card-text>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'MailboxItemList',
  props: {itemList: Array, unreadCheckEnabled: Boolean},
  computed: {
    ...mapGetters(['portalData', 'user', 'fullName'])
  },
  methods: {
    handleClick(id) {
      this.$emit('openItem', id);
    },
    formatFromInfo(from) {
      return from.kontoId === this.user.personIdentifier
          ? this.fullName
          : (from.kontoId || from.receiver)
    }
  }
};
</script>
