import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '../components/views/Home';
import AdministrationRequest from '../components/views/AdministrationRequest';
import Mailbox from '../components/views/Mailbox';
import ErrorPage from '../components/views/ErrorPage';

Vue.use(VueRouter);

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
    props: {pageKey: 'home'},
    beforeEnter: (_, __, next) => {
      const {user} = store.getters;
      if (!user.loggedIn) {
        store.dispatch('setPrivacy', false);
      }
      next();
    }
  },
  {
    name: 'AdministrationRequest',
    path: '/administration-request',
    component: AdministrationRequest,
    props: {pageKey: 'administrationRequest'},
    meta: {authRequired: true}
  },
  {
    name: 'Mailbox',
    path: '/mailbox',
    component: Mailbox,
    props: {pageKey: 'mailbox'},
    beforeEnter: async (to, from, next) => {
      const {user, loa, isPostfachServiceAllowed} = store.getters;

      if (isPostfachServiceAllowed) {
        if (!user.loggedIn) {
          const authData = {
            loaKey: loa,
            scenario: 'no-requested-attributes',
            redirectURL: `${window.location.origin}/mailbox`
          };
          await store.dispatch('setAttributeConfiguration', []);
          await store.dispatch('authenticate', authData);
        } else {
          next();
        }
      } else {
        next('/');
      }
    }
  },
  {
    name: 'Message',
    path: '/mailbox/:messageId/:loa',
    component: Mailbox,
    props: {pageKey: 'mailbox'},
    meta: {authRequired: true},
    beforeEnter: async (to, from, next) => {
      const {user} = store.getters;
      const loaList = ['normal', 'substantiell', 'hoch'];

      if (loaList.includes(to.params.loa)) {
        if (!user.loggedIn) {
          const authData = {
            loaKey: to.params.loa,
            scenario: 'no-requested-attributes',
            redirectURL: window.location
          };
          await store.dispatch('setAttributeConfiguration', []);
          await store.dispatch('authenticate', authData);
        } else {
          next();
        }
      } else {
        next('/');
      }
    }
  },
  {
    name: 'MissingAttribute',
    path: '/missing-attribute',
    component: ErrorPage,
    props: {errorKey: 'missingAttribute'},
    meta: {tooltipsDisabled: true}
  },
  {
    name: 'NotFound',
    path: '*',
    component: ErrorPage,
    props: {errorKey: 'notFound'},
    meta: {tooltipsDisabled: true}
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
});

router.beforeEach(async (to, from, next) => {
  const {authRequired} = to.meta;
  if (!from.name) {
    await store.dispatch('fetchUserInfo');
  }
  const {user, privacyAccepted} = store.getters;

  if (to.name === 'Message' && privacyAccepted) {
    next();
  } else if (authRequired && !user.loggedIn) {
    next('/');
  } else {
    next();
  }
});

export default router;
