<template>
  <v-sheet>
    <v-divider class="mx-4"/>
    <v-card-text class="pa-2">
      <v-btn
          class="text-subtitle-2"
          text
          @click="toggleAttachments">
        <span>{{ $t('messageModal.attachments') }}</span>
        <v-icon small>{{ buttonIcon }}</v-icon>
      </v-btn>
      <v-expand-transition>
        <v-sheet
            v-show="showAttachments"
            class="pl-4">
          <v-list>
            <v-list-item
                v-for="attachment in attachments"
                :key="attachment.id">
              <v-sheet class="grey--text text--darken-2 mr-2">
                {{ attachment.name }}
              </v-sheet>
              <v-btn
                  icon
                  x-small
                  @click="downloadAttachment(attachment)">
                <v-icon class="pa-2">mdi-download</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-expand-transition>
    </v-card-text>
  </v-sheet>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'MessageAttachments',
  props: {attachments: Array, messageId: String},
  data() {
    return {
      showAttachments: false
    };
  },
  computed: {
    ...mapGetters(['configData']),
    buttonIcon() {
      if (this.showAttachments) {
        return 'mdi-chevron-up';
      } else {
        return 'mdi-chevron-down';
      }
    }
  },
  methods: {
    ...mapActions(['setNotification']),
    toggleAttachments() {
      this.showAttachments = !this.showAttachments;
    },
    downloadAttachment(attachment) {
      const {messagesURL} = this.configData;
      axios.get(
          `${messagesURL}/${this.messageId}/attachments/${attachment.id}`,
          {withCredentials: true, responseType: 'blob'}
      )
      .then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', attachment.name);
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch(() => {
        const newNotification = {
          type: 'error',
          textKey: 'downloadAttachmentError',
          show: true
        };
        this.setNotification(newNotification);
      });
    }
  }
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}

.v-list-item {
  min-height: 25px;
}
</style>
