<template>
  <v-sheet>
    <v-card-text class="py-0 px-2 d-flex flex-wrap justify-space-between">
      <v-list>
        <v-list-item
            v-for="info in fromToInfo"
            :key="info.title"
            class="align-start">
          <v-sheet
              :class="portalData.color.text"
              min-width="60px">
            {{ info.title }}:
          </v-sheet>
          <v-sheet max-width="480px">{{ info.value }}</v-sheet>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item
            v-for="info in dateTimeInfo"
            :key="info.title"
            class="align-start">
          <v-sheet
              :class="portalData.color.text"
              min-width="60px">
            {{ info.title }}:
          </v-sheet>
          <v-sheet>{{ info.value }}</v-sheet>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider class="mx-4"/>
    <v-card-text
        class="px-10 grey--text text--darken-3"
        v-html="sanitize(message.payload)"/> <!-- njsscan-ignore: vue_template -->
    <message-notification-info
        v-if="message.notificationInfo"
        :messageId="message.id"
        :notificationInfo="message.notificationInfo"/>
  </v-sheet>
</template>

<script>
import {mapGetters} from 'vuex';
import MessageNotificationInfo from './MessageNotificationInfo';
import sanitizeHtml from 'sanitize-html';

export default {
  name: 'MessageSingleView',
  components: {MessageNotificationInfo},
  props: {message: Object},
  computed: {
    ...mapGetters(['portalData', 'user', 'fullName']),
    fromToInfo() {
      let defaultFrom = this.message.from.kontoId || this.message.from.receiver;
      return [
        {
          title: this.$t('messageModal.from'),
          value: this.message.from.kontoId === this.user.personIdentifier
              ? this.fullName
              : defaultFrom
        }, {
          title: this.$t('messageModal.to'),
          value: this.message.to.kontoId === this.user.personIdentifier
              ? this.fullName
              : this.message.to.kontoId
        }, {
          title: this.$t('messageModal.subject'),
          value: this.message.subject || "---"
        }
      ];
    },
    dateTimeInfo() {
      return [
        {
          title: this.$t(`messageModal.date`),
          value: this.$date(this.message.created).format('ll')
        },
        {
          title: this.$t(`messageModal.time`),
          value: this.$date(this.message.created).format('LT')
        }, {
          title: 'LOA',
          value: this.$t(`loa.${this.message.loa}`)
        }
      ];
    }
  },
  methods: {
    sanitize(messageBody) {
      const options = {allowedTags: ['br', 'strong', 'em'], disallowedTagsMode: 'recursiveEscape'};
      return sanitizeHtml(messageBody, options);
    }
  }
};
</script>

<style scoped>
.v-list-item {
  min-height: 25px;
}
</style>
