const initialState = {
  privacyAccepted: false,
  portalData: {},
  configData: {},
  scenariosData: [],
  user: {
    loggedIn: false
  },
  tipPath: '',
  scenario: 'default',
  loa: 'normal',
  transactionAttestation: '',
  attributeConfiguration: [],
  attributeReport: [],
  notification: {},
  isLoading: false,
  messageModalContent: null,
  showMessageModal: false
};
export default initialState;
