<template>
  <v-card class="ma-2">
    <v-card-title class="text-subtitle-2">
      {{ $t('attributes.reportTitle') }}
    </v-card-title>
    <v-divider/>
    <v-data-table
        :headers="headers"
        :items="attributeReport"
        disable-pagination
        disable-sort
        hide-default-footer
        mobile-breakpoint="700">
      <template v-slot:item.required="{ item }">
        <v-checkbox
            v-model="item.required"
            class="ma-0 pa-0"
            dense
            disabled
            hide-details/>
      </template>
      <template v-slot:item.values="{ item }">
        <v-sheet
            class="text-truncate"
            color="transparent"
            max-width="320px">
          {{ item.values === 'notSupported' ? $t('attributes.notSupported') : item.values }}
        </v-sheet>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'AttributeReport',
  data() {
    return {
      assertedAttributes: []
    };
  },
  computed: {
    ...mapGetters(['user', 'configData', 'attributeReport', 'attributeConfiguration']),
    headers() {
      return [
        {text: 'FriendlyName', value: 'friendlyName'},
        {text: this.$t('attributes.required'), value: 'required'},
        {text: this.$t('attributes.value'), value: 'values'}
      ];
    }
  },
  methods: {
    ...mapActions(['setAttributeReport', 'setNotification']),
    async generateAttributeReport() {
      const attributeReport = [];

      try {
        const response = await axios.get(
            this.configData.assertedAttributesURL,
            {withCredentials: true}
        );
        this.assertedAttributes = response.data;
      } catch {
        const newNotification = {
          type: 'error',
          textKey: 'getAttributesError',
          show: true
        };
        this.setNotification(newNotification);
        return;
      }

      if (this.attributeConfiguration.length) {
        for (let attribute of this.attributeConfiguration) {
          const assertedAttribute = this.assertedAttributes.find(
              assertedAttribute => assertedAttribute.name === attribute.name);

          attributeReport.push({
            ...attribute,
            values: assertedAttribute ? assertedAttribute.values : 'notSupported'
          });
        }
      }

      // handles the items that are not requested, but asserted anyway
      for (let assertedAttribute of this.assertedAttributes) {
        const attribute = attributeReport.find(
            attribute => attribute.name === assertedAttribute.name);
        if (!attribute) {
          attributeReport.push({
            ...assertedAttribute,
            required: false
          });
        }
      }

      this.setAttributeReport(attributeReport);
    }
  },
  created() {
    if (this.user.loggedIn && !this.attributeReport.length) {
      this.generateAttributeReport();
    }
  }
};
</script>

<style scoped>
.text-truncate:hover {
  white-space: normal !important;
}
</style>
