const privacyAccepted = (state) => state.privacyAccepted;
const configData = (state) => state.configData;
const portalData = (state) => state.portalData;
const user = (state) => state.user;
const fullName = (state) => `${state.user.firstName} ${state.user.familyName}`;
const isPersonIdentifierRequested = (state) => {
  if (state.scenario === 'customized-attributes') {
    const personIdentifier = state.attributeConfiguration.find(
        attribute => attribute.friendlyName === 'PersonIdentifier');
    return personIdentifier && personIdentifier.requested;
  }

  return true;
};
const isPostfachReferenceRequested = (state) => {
  if (state.scenario === 'customized-attributes') {
    const postfachReference = state.attributeConfiguration.find(
        attribute => attribute.friendlyName === 'PostfachReference');
    return postfachReference && postfachReference.requested;
  }

  return true;
};
const isPostfachServiceAllowed = (state, getters) => {
  return state.configData.isPostfachActivated
      && getters.isPostfachReferenceRequested;
};
const isRemoteUser = (state, getters) => {
  // when the postfach service is not allowed remote login is still possible, but local user interface is displayed
  if (!getters.isPostfachServiceAllowed) {
    return false;
  }

  if (state.user.loggedIn) {
    const {finkActorIdUPIS} = state.configData;
    const userPortal = state.user.postfachReference.split('//')[0];
    return finkActorIdUPIS !== userPortal;
  }
};
const tipPath = (state) => state.tipPath;
const scenario = (state) => state.scenario;
const loa = (state) => state.loa;
const transactionAttestation = (state) => state.transactionAttestation;
const scenariosData = (state) => state.scenariosData;
const attributeConfiguration = (state) => state.attributeConfiguration;
const attributeReport = (state) => state.attributeReport;
const notification = (state) => state.notification;
const isLoading = (state) => state.isLoading;
const showMessageModal = (state) => state.showMessageModal;
const messageModalContent = (state) => state.messageModalContent;

export default {
  privacyAccepted,
  configData,
  portalData,
  user,
  fullName,
  isPersonIdentifierRequested,
  isPostfachReferenceRequested,
  isPostfachServiceAllowed,
  isRemoteUser,
  tipPath,
  scenario,
  loa,
  transactionAttestation,
  scenariosData,
  attributeConfiguration,
  attributeReport,
  notification,
  isLoading,
  showMessageModal,
  messageModalContent
};
