<template>
  <v-dialog
      :value="showNotificationModal"
      max-width="500"
      persistent>
    <v-card v-if="notificationKey">
      <div v-if="title">
        <v-card-title class="justify-space-between align-start pa-0">
          <div class="px-6 py-4">
            {{ $t(`notificationModal.${notificationKey}.title`) }}
          </div>
        </v-card-title>
        <v-divider/>
      </div>
      <v-card-title v-else class="pa-2">
        <v-icon color="primary" large>mdi-information</v-icon>
      </v-card-title>
      <v-card-text class="py-5">
        {{ $t(`notificationModal.${notificationKey}.text`) }}
      </v-card-text>
      <v-card-actions v-if="actions">
        <v-spacer/>
        <v-btn
            :class="portalData.color.bg"
            class="white--text"
            @click="closeModal">
          {{ $t('notificationModal.button.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'NotificationModal',
  props: {
    showNotificationModal: Boolean,
    notificationKey: String,
    title: Boolean,
    actions: Boolean
  },
  computed: mapGetters(['portalData']),
  methods: {
    closeModal() {
      this.$emit('closeModal');
    }
  }
};
</script>
