<template>
  <v-sheet>
    <v-card class="my-4">
      <v-card-text>{{ $t('administrationRequest.infoBox') }}</v-card-text>
    </v-card>
    <v-text-field
        v-model="user.firstName"
        :label="$t('administrationRequest.firstNameLabel')"
        disabled/>
    <v-text-field
        v-model="user.familyName"
        :label="$t('administrationRequest.lastNameLabel')"
        disabled/>
    <v-text-field
        v-model="user.emailAddress"
        :label="$t('administrationRequest.emailLabel')"
        disabled/>
    <v-card class="my-4 py-1">
      <v-card-text class="body-2">
        <p>{{ $t('administrationRequest.infoText1') }}</p>
        <p>{{ $t('administrationRequest.infoText2') }}</p>
        <p>{{ $t('administrationRequest.infoNecessaryAttributes') }}</p>
        <ul>
          <li>{{ $t('administrationRequest.familyName') }}</li>
          <li>{{ $t('administrationRequest.firstName') }}</li>
          <li>{{ $t('administrationRequest.birthName') }}</li>
          <li>{{ $t('administrationRequest.birthDateAndPlace') }}</li>
          <li>{{ $t('administrationRequest.bornAbroadCountry') }}</li>
        </ul>
      </v-card-text>
      <v-card-text class="body-2">
        <p>{{ $t('administrationRequest.infoOtherAttributes') }}</p>
        <ul>
          <li>{{ $t('administrationRequest.mobilePhoneNumber') }}</li>
          <li>{{ $t('administrationRequest.gender') }}</li>
        </ul>
      </v-card-text>
      <v-card-text class="body-2">
        <p>{{ $t('administrationRequest.infoLOA') }}</p>
        <ul>
          <li>{{ $t('administrationRequest.high') }}</li>
          <li>{{ $t('administrationRequest.substantial') }}</li>
          <li>{{ $t('administrationRequest.low') }}</li>
        </ul>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'AdministrationRequestInfo',
  computed: {
    ...mapGetters(['user'])
  }
};
</script>
<style scoped>
ul {
  list-style-type: '- ';
}
</style>
