import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import actions from './actions';
import mutations from './mutations';
import getters from '@/store/getters';
import initialState from '@/store/state';

Vue.use(Vuex);
const createStore = key => new Vuex.Store({
  state: initialState,
  mutations,
  actions,
  getters,
  modules: {},
  plugins: [
    createPersistedState({key, storage: localStorage})
  ]
});

export default createStore('member-app');
