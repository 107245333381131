<template>
  <v-dialog
      v-model="showDialog"
      persistent
      width="unset">
    <v-form ref="form">
      <v-card class="pa-4">
        <v-card-title class="justify-center text-h5 mb-8 grey lighten-4">
          Cookies und Testdaten
        </v-card-title>
        <v-card-text class="text-body-1">
          Sehr geehrte Nutzerin, sehr geehrter Nutzer,<br><br>
          Sie befinden sich auf der Integrationsumgebung der Föderation Interoperabler
          Nutzerkonten.<br>
          Diese Umgebung ist eine Testumgebung.
        </v-card-text>
        <v-card-text class="font-weight-medium text-body-1">
          Diese Webseite verwendet Cookies, um bestimmte Funktionen zu ermöglichen und das Angebot
          zu verbessern.
          <v-checkbox
              v-model="cookiesAccepted"
              :rules="[v => !!v || 'Sie müssen der Verwendung von Cookies zustimmen, um fortzufahren!']"
              class="text-body-2 mb-2"
              label="Hiermit stimme ich der Nutzung von Cookies zu."
              required/>
          Bitte benutzen Sie für Ihre Tests keine realen E-Mail-Adressen oder personenbezogene
          Realdaten.
          <v-checkbox
              v-model="testDataUsageAccepted"
              :rules="[v => !!v || 'Sie müssen der Verwendung von Testdaten zustimmen, um fortzufahren!']"
              class="text-body-2"
              label="Hiermit bestätige ich, dass ich keine Realdaten für die Tests benutzen werde."
              required/>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="submitPrivacy">
            Ich akzeptiere
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import {mapActions} from 'vuex';

export default {
  data() {
    return {
      showDialog: true,
      cookiesAccepted: false,
      testDataUsageAccepted: false
    };
  },
  methods: {
    ...mapActions(['setPrivacy']),
    submitPrivacy() {
      if (this.$refs.form.validate()) {
        this.setPrivacy(true);
        this.showDialog = false;
      }
    }
  }
};
</script>
