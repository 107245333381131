<template>
  <v-app>
    <page-header :class="{'blur-content': !privacyAccepted}"/>
    <v-main
        :class="{'blur-content': !privacyAccepted}"
        class="align-center">
      <notification/>
      <loading-spinner v-show="isLoading"/>
      <v-fade-transition>
        <router-view v-show="!isLoading"/>
      </v-fade-transition>
    </v-main>
    <v-divider/>
    <page-footer :class="{'blur-content': !privacyAccepted}"/>
    <privacy-modal v-if="!privacyAccepted"/>
    <tooltips-assistant
        v-if="!isLoading && !$route.meta.tooltipsDisabled"
        :class="{'blur-content': !privacyAccepted}"/>
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';
import PrivacyModal from './components/PrivacyModal';
import TooltipsAssistant from './components/TooltipsAssistant';
import Notification from './components/Notification';
import LoadingSpinner from './components/LoadingSpinner';

export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter,
    PrivacyModal,
    TooltipsAssistant,
    Notification,
    LoadingSpinner
  },
  computed: {
    ...mapGetters(['privacyAccepted', 'isLoading'])
  },
  methods: {
    ...mapActions(['setMouseEnterTipPath', 'setNotification']),
    addTooltipsEvents() {
      let tipElements = document.querySelectorAll('[data-tip-key]');
      for (let elem of tipElements) {
        elem.addEventListener('mouseenter', this.setMouseEnterTipPath);
      }
    }
  },
  created() {
    this.setNotification({});
  },
  mounted() {
    this.$nextTick(this.addTooltipsEvents);
  },
  updated() {
    this.$nextTick(this.addTooltipsEvents);
  }
};
</script>
<style>
.container {
  max-width: 1200px;
  height: 100%;
}

.blur-content {
  filter: blur(6px);
}

/*scrollbar styles for chrome, edge*/
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.4);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
}

/*firefox only*/
* {
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(255, 255, 255, 0.4);
  scrollbar-width: thin;
}
</style>
