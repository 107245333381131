<template>
  <v-sheet class="text-center">
    <v-progress-circular
        :color="portalData.color.bg"
        :size="70"
        :width="7"
        indeterminate/>
  </v-sheet>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'LoadingSpinner',
  computed: {
    ...mapGetters(['isLoading', 'portalData'])
  }
};
</script>
