<template>
  <v-card class="ma-2">
    <v-card-title class="text-subtitle-2">
      {{ $t('attributes.configurationTitle') }}
    </v-card-title>
    <v-divider/>
    <v-data-table
        :headers="headers"
        :items="attributes"
        calculate-widths
        class="ma-2"
        disable-pagination
        disable-sort
        hide-default-footer
        mobile-breakpoint="0">
      <template v-slot:item.requested="{item}">
        <v-checkbox
            v-model="item.requested"
            :color="portalData.color.bg"
            class="ma-0 pa-0"
            dense
            hide-details
            @change="setCustomScenario(); updateRequired(item)"/>
      </template>
      <template v-slot:item.required="{item}">
        <v-checkbox
            v-model="item.required"
            :color="portalData.color.bg"
            :disabled="!item.requested"
            class="ma-0 pa-0"
            dense
            hide-details
            @change="setCustomScenario"/>
      </template>
      <template v-slot:item.isDeletable="{item}">
        <v-btn
            v-if="item.isDeletable"
            :ripple=false
            icon
            plain
            text
            x-small
            @click="deleteItem(item)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:body.append>
        <tr>
          <td></td>
          <td></td>
          <td>
            <v-text-field
                v-model="friendlyName"
                :error-messages="friendlyNameErrors"
                placeholder="FriendlyName"/>
          </td>
          <td>
            <v-text-field
                v-model="name"
                :error-messages="nameErrors"
                placeholder="URN-OID"/>
          </td>
          <td>
            <v-btn
                :color="portalData.color.bg"
                dark
                fab
                x-small
                @click="addItem">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';

export default {
  name: 'AttributeConfiguration',
  mixins: [validationMixin],
  validations: {
    friendlyName: {required},
    name: {
      required,
      isAvailable() {
        const usedNames = this.scenariosData[0].attributeList.map(attribute => attribute.name);
        return usedNames.findIndex(name => name === this.name) === -1;
      }
    }
  },
  data() {
    return {
      friendlyName: '',
      name: ''
    };
  },
  computed: {
    ...mapGetters(['portalData', 'scenario', 'scenariosData', 'attributeConfiguration']),
    headers() {
      return [
        {text: this.$t('attributes.requested'), value: 'requested'},
        {text: this.$t('attributes.required'), value: 'required'},
        {text: 'FriendlyName', value: 'friendlyName', width: '200px'},
        {text: 'URN-OID', value: 'name'},
        {text: '', value: 'isDeletable', align: 'center'}
      ];
    },
    attributes: {
      get() {
        return this.attributeConfiguration;
      },
      set(attributes) {
        this.setAttributeConfiguration(attributes);
      }
    },
    friendlyNameErrors() {
      const errors = [];
      if (!this.$v.friendlyName.$dirty) {
        return errors;
      }
      !this.$v.friendlyName.required && errors.push(this.$t(
          'attributes.validation.friendlyNameRequired'));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }
      !this.$v.name.required && errors.push(this.$t('attributes.validation.nameRequired'));
      !this.$v.name.isAvailable && errors.push(this.$t('attributes.validation.nameInUse'));
      return errors;
    }
  },
  methods: {
    ...mapActions(['setAttributeConfiguration', 'setScenario']),
    updateRequired(item) {
      if (!item.requested && item.required) {
        item.required = false;
      }
    },
    setCustomScenario() {
      if (this.scenario !== 'customized-attributes') {
        this.setScenario('customized-attributes');
      }
    },
    addItem() {
      this.$v.$touch();

      if (this.nameErrors.length || this.friendlyNameErrors.length) {
        return;
      } else {
        this.$v.$reset();
      }

      this.setCustomScenario();

      this.attributes = [
        ...this.attributeConfiguration,
        {
          requested: true,
          required: true,
          friendlyName: this.friendlyName,
          name: this.name,
          isDeletable: true
        }];

      this.friendlyName = '';
      this.name = '';
    },
    deleteItem(item) {
      const index = this.attributes.indexOf(item);
      this.attributes.splice(index, 1);
    }
  }
};
</script>

<!-- disable hover highlight effect on the table -->
<style lang="scss" scoped>
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
