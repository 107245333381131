<template>
  <v-sheet
      :data-page="pageKey"
      :data-tip-key="`loaSelection.${selectedLOA}`"
      class="my-3"
      height="45px"
      width="450px">
    <v-select
        v-model="selectedLOA"
        :disabled="user.loggedIn"
        :items="loaList"
        :label="$t('loa.label')"
        dense
        outlined
        rounded
        @change="updateTipText"/>
  </v-sheet>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'LoaSelection',
  props: {pageKey: String},
  computed: {
    ...mapGetters(['configData', 'user', 'loa']),
    loaList() {
      const loaList = ['normal', 'substantiell', 'hoch'];
      const {isNiedrigActivated, isBasisregistrierungActivated} = this.configData;

      if (isNiedrigActivated) {
        loaList.shift()
        loaList.unshift('niedrig')
      }
      if (isBasisregistrierungActivated) {
        loaList.unshift('basisregistrierung')
      }
      return loaList.map(loaKey => {
        return {text: this.$t(`loa.${loaKey}`), value: loaKey};
      });
    },
    selectedLOA: {
      get() {
        return this.loa;
      },
      set(newLoa) {
        this.setLOA(newLoa);
      }
    }
  },
  methods: {
    ...mapActions(['setLOA', 'setTipPath']),
    updateTipText() {
      this.setTipPath({pageKey: this.pageKey, tipKey: `loaSelection.${this.selectedLOA}`});
    }
  }
};
</script>
