<template>
  <v-container>
    <v-row class="ma-15 text-h5 justify-center text-center">
      <div>{{ $t(`errorPage.${errorKey}`) }}</div>
    </v-row>
    <v-divider/>
    <v-row class="ma-15 flex-column align-center">
      <div>{{ $t('errorPage.goBackTo') }}</div>
      <router-link
          :to="{name: 'Home'}"
          class="text-h6 black--text">
        {{ configData.portalDisplayName }}
      </router-link>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'ErrorPage',
  props: {errorKey: String},
  computed: {
    ...mapGetters(['configData'])
  },
  metaInfo() {
    return {
      title: this.$t('documentTitle.error')
    };
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
