<template>
  <v-dialog
      :value="showConfirmationModal"
      max-width="400"
      persistent>
    <v-card>
      <v-card-text
          v-if="textKey"
          v-html="$t(`confirmationModal.${textKey}`)"
          class="pt-5 pb-2"/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            :color="portalData.color.bg"
            text
            @click="closeModal(true)">
          {{ $t('confirmationModal.yes') }}
        </v-btn>
        <v-btn
            text
            @click="closeModal(false)">
          {{ $t('confirmationModal.no') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'ConfirmationModal',
  props: {showConfirmationModal: Boolean, textKey: String},
  computed: mapGetters(['portalData']),
  methods: {
    closeModal(isConfirmed) {
      this.$emit('closeModal', isConfirmed);
    }
  }
};
</script>
