<template>
  <v-sheet
      v-if="!user.loggedIn"
      :class="boxShadowApplied && 'box-shadow'"
      class="pa-3 ma-3 sticky">
    <div class="text-center text-subtitle-2 mb-2">
      {{ $t('scenario.title') }}
    </div>
    <v-sheet class="d-flex flex-wrap justify-space-around">
      <v-sheet
          :data-page="pageKey"
          class="ma-3"
          data-tip-key="scenarioSelection"
          height="45px"
          width="300px">
        <v-select
            v-model="selectedScenario"
            :disabled="isDisabled"
            :items="scenarios"
            :label="$t('scenario.label')"
            dense
            outlined
            rounded
            @change="loadAttributes"/>
      </v-sheet>
      <v-card
          class="pb-2 ma-3"
          width="600px">
        <v-card-text class="pa-2">
          <v-icon id="info-icon">mdi-information-variant</v-icon>
        </v-card-text>
        <v-sheet
            class="d-flex align-center pl-3"
            min-height="80px">
          <!-- @formatter:off -->
          <v-card-text
              class="py-2 text-body-2 grey--text text--darken-3"
              v-html="$t(`scenario.${selectedScenario}.description`)"/> <!-- njsscan-ignore: vue_template -->
          <!-- @formatter:on -->
        </v-sheet>
      </v-card>
    </v-sheet>
  </v-sheet>
  <v-sheet v-else>
    <v-card
        class="ma-2"
        elevation="1"
        min-height="180px"
        width="400px">
      <v-card-title class="text-subtitle-2">
        {{ $t('scenario.label') }}
      </v-card-title>
      <v-divider/>
      <v-card-subtitle class="font-weight-bold grey--text text--darken-3">
        {{ $t(`scenario.${selectedScenario}.name`) }}
      </v-card-subtitle>
      <!-- @formatter:off -->
      <v-card-text
          class="grey--text text--darken-3"
          v-html="$t(`scenario.${selectedScenario}.description`)"></v-card-text> <!-- njsscan-ignore: vue_template -->
      <!-- @formatter:on -->
    </v-card>
  </v-sheet>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'ScenarioSelection',
  props: {pageKey: String},
  data() {
    return {
      scenarioCodes: ['default'],
      isDisabled: true,
      boxShadowApplied: false
    };
  },
  computed: {
    ...mapGetters(['user', 'configData', 'scenariosData', 'scenario']),
    scenarios() {
      return this.scenarioCodes.map(scenarioCode => ({
        text: this.$t(`scenario.${scenarioCode}.name`),
        value: scenarioCode
      }));
    },
    selectedScenario: {
      get() {
        return this.scenario;
      },
      set(newScenario) {
        this.setScenario(newScenario);
      }
    }
  },
  methods: {
    ...mapActions(
        ['setScenario', 'setNotification', 'setScenariosData', 'setAttributeConfiguration']),
    loadAttributes() {
      const scenarioAttributes = this.scenariosData.find(
          scenario => scenario.scenarioCode === this.scenario).attributeList;

      // use a deep copy of the scenarioAttributes array while setting the state
      this.setAttributeConfiguration(JSON.parse(JSON.stringify(scenarioAttributes)));
    },
    updateView() {
      this.scenarioCodes = this.scenariosData.map(scenario => scenario.scenarioCode);
      this.selectedScenario = 'default';
      this.isDisabled = false;
      this.loadAttributes();
    },
    onScroll() {
      this.boxShadowApplied = document.querySelector('.sticky').getBoundingClientRect().top === 0;
    }
  },
  async created() {
    if (this.user.loggedIn) {
      return;
    }

    window.addEventListener('scroll', this.onScroll);

    try {
      const {data} = await axios.get(this.configData.scenariosURL, {timeout: 2000});
      const scenariosData = JSON.parse(JSON.stringify(data));

      for (let scenario of scenariosData) {
        let requested = scenario.scenarioCode !== 'customized-attributes';

        scenario.attributeList = scenario.attributeList.map(attribute => ({
          ...attribute,
          requested
        }));
      }

      await this.setScenariosData(scenariosData);
      this.updateView();
    } catch {
      this.isDisabled = true;
      const newNotification = {
        type: 'error',
        textKey: 'getScenariosError',
        show: true
      };
      this.setNotification(newNotification);
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }
};
</script>

<style scoped>
#info-icon {
  background-color: #91c8fa;
  border-radius: 50%;
  color: white;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 200;
}

.box-shadow {
  box-shadow: 0 10px 5px -5px rgba(0, 0, 0, 0.2);
}
</style>
