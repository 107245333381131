<template>
  <v-container>
    <div class="text-center text-h6 ma-5">
      {{ $t('home.description') }}
    </div>
    <v-row class="my-5 justify-space-around">
      <scenario-selection :pageKey="pageKey"/>
      <attribute-configuration v-if="!user.loggedIn"/>
      <attribute-report v-if="user.loggedIn"/>
    </v-row>
    <v-divider/>
    <v-row class="ma-5 flex-column align-center">
      <loa-selection :pageKey="pageKey"/>
      <transaction-attestation-selection v-if="configData.isTransactionAttestationActivated"/>
      <v-btn
          v-if="!user.loggedIn"
          :color="portalData.color.bg"
          class="my-5"
          elevation="6"
          outlined
          text
          @click="startAuthentication">
        {{ $t('home.requestButton') }}
      </v-btn>
    </v-row>
    <confirmation-modal
        :showConfirmationModal="showConfirmationModal"
        :textKey="contentTextKey"
        @closeModal="closeConfirmationModal"/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import AttributeConfiguration from '../AttributeConfiguration';
import AttributeReport from '../AttributeReport';
import ScenarioSelection from '../ScenarioSelection';
import LoaSelection from '../LoaSelection';
import TransactionAttestationSelection from "../TransactionAttestationSelection.vue";
import ConfirmationModal from '../ConfirmationModal';
import router from '../../router';

export default {
  name: 'Home',
  components: {
    ScenarioSelection,
    AttributeConfiguration,
    AttributeReport,
    LoaSelection,
    TransactionAttestationSelection,
    ConfirmationModal
  },
  props: {pageKey: String},
  metaInfo() {
    return {
      title: this.configData.portalDisplayName
    };
  },
  data() {
    return {
      generalTipKey: 'general',
      showConfirmationModal: false,
      isAttributeConfigurationValid: false,
      contentTextKey: ''
    };
  },
  computed: {
    ...mapGetters(
        ['configData', 'portalData', 'user', 'loa', 'transactionAttestation', 'scenario',
          'isPersonIdentifierRequested', 'isPostfachReferenceRequested']),
    authData() {
      const redirectURL = window.location + 'administration-request';

      return {
        loaKey: this.loa,
        transactionAttestation: this.transactionAttestation,
        scenario: this.scenario,
        redirectURL
      };
    },
  },
  methods: {
    ...mapActions(['authenticate', 'setTipPath']),
    setGeneralTipKey() {
      if (this.user.loggedIn && this.loa) {
        this.generalTipKey = `loggedIn.${this.loa}`;
      } else {
        this.generalTipKey = 'general';
      }
    },
    validateAttributeConfiguration() {
      if (!this.isPersonIdentifierRequested) {
        this.contentTextKey = "missingPersonIdentifier";
        this.isAttributeConfigurationValid = false;
      } else if (this.configData.isPostfachActivated && !this.isPostfachReferenceRequested) {
        this.contentTextKey = "missingPostfachReference";
        this.isAttributeConfigurationValid = false;
      } else {
        this.isAttributeConfigurationValid = true;
      }
    },
    startAuthentication() {
      this.validateAttributeConfiguration();

      if (this.scenario === 'customized-attributes' && !this.isAttributeConfigurationValid) {
        this.openConfirmationModal();
      } else {
        this.authenticate(this.authData);
      }
    },
    openConfirmationModal() {
      this.showConfirmationModal = true;
    },
    closeConfirmationModal(isConfirmed) {
      if (isConfirmed) {
        if (!this.isPersonIdentifierRequested) {
          router.push({name: 'MissingAttribute'});
        } else {
          this.authenticate(this.authData);
        }
      } else {
        this.showConfirmationModal = false;
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setGeneralTipKey();
    this.setTipPath({pageKey: this.pageKey, tipKey: this.generalTipKey});
  }
};
</script>
