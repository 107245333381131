import Vue from 'vue';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
require('dayjs/locale/de');
require('dayjs/locale/en');

dayjs.locale(localStorage.getItem('language') || 'de');

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs;
    }
  }
});
