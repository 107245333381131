<template>
  <v-container>
    <v-row class="justify-center">
      <v-col class="col-10">
        <h2 class="text-h6 text-center">
          {{ $t('administrationRequest.title') }} ({{ $t(`loa.${loa}`) }})
        </h2>
        <v-card
            v-if="isRemoteUser"
            class="mt-4">
          <v-card-subtitle class="text-subtitle-1 font-weight-bold">
            {{ $t('serviceConfiguration.title') }}
          </v-card-subtitle>
          <!-- @formatter:off -->
          <v-card-text v-html="$t('serviceConfiguration.text')"/> <!-- njsscan-ignore: vue_template -->
          <!-- @formatter:on -->
          <v-form class="px-4">
            <v-select
                v-model="documentTypId"
                :items="formattedDocumentTypIdList"
                dense
                outlined/>
            <v-radio-group
                v-model="directMessage"
                class="ma-0">
              <v-radio
                  v-for="option in directMessageOptions"
                  :key="option.value"
                  :label="$t(`serviceConfiguration.directMessage.${option.textKey}`)"
                  :value="option.value"/>
            </v-radio-group>
            <v-checkbox
                v-model="emailNotification"
                :label="$t('serviceConfiguration.emailNotification')"
                class="ma-0"/>
          </v-form>
        </v-card>
        <administration-request-info/>
        <v-form class="mt-4">
          <v-text-field
              :label="$t('messageForm.subject')"
              :value="subject"
              disabled/>
          <v-textarea
              v-model="description"
              :counter="250"
              :error-messages="descriptionErrors"
              :label="$t('messageForm.description')"
              @input="$v.description.$touch()"/>
          <v-select
              v-if="configData.isUploadActivated"
              v-model="selectedAttachmentScenario"
              :hint="$t('messageForm.attachmentsHint')"
              :items="attachmentScenarios"
              :label="$t(`messageForm.attachments`)"
              class="mt-5"
              dense
              outlined
              persistent-hint/>
          <v-checkbox
              v-model="checkbox"
              :error-messages="checkboxErrors"
              :label="$t('messageForm.dataSecurityQuestion')"
              class="mb-5"
              @change="$v.checkbox.$touch()"/>
          <v-btn
              :color="portalData.color.bg"
              :disabled="isFormInvalid"
              class="mr-4"
              elevation="6"
              outlined
              text
              @click="submit">
            {{ $t('messageForm.submit') }}
          </v-btn>
          <v-btn
              :color="portalData.color.bg"
              elevation="6"
              outlined
              text
              @click="clear">
            {{ $t('messageForm.clear') }}
          </v-btn>
        </v-form>
        <notification-modal
            v-if="!isPostfachServiceAllowed"
            :notificationKey="contentTextKey"
            :showNotificationModal="showNotificationModal"
            actions
            title
            @closeModal="closeNotificationModal"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {maxLength, required} from 'vuelidate/lib/validators';
import AdministrationRequestInfo from '../AdministrationRequestInfo';
import NotificationModal from '../NotificationModal';

export default {
  name: 'AdministrationRequest',
  components: {AdministrationRequestInfo, NotificationModal},
  props: {pageKey: String},
  mixins: [validationMixin],
  metaInfo() {
    return {
      title: this.$t(`documentTitle.administrationRequest`)
    };
  },
  validations: {
    description: {required, maxLength: maxLength(250)},
    checkbox: {
      checked(val) {
        return val;
      }
    }
  },
  data() {
    return {
      showNotificationModal: false,
      contentTextKey: '',
      documentTypId: 'message',
      documentTypIdList: [
        {
          textKey: 'withoutLegalEffect',
          value: 'message'
        },
        {
          textKey: 'wrongDocumentTypId',
          value: 'wrong-documenttypid'
        },
        {
          textKey: 'withoutDocumentTypId',
          value: ''
        }],
      directMessage: false,
      directMessageOptions: [
        {
          textKey: 'directMessageText',
          value: true
        },
        {
          textKey: 'notificationText',
          value: false
        }],
      emailNotification: false,
      description: '',
      selectedAttachmentScenario: 0,
      checkbox: false,
      messageId: ''
    };
  },
  watch: {
    emailNotification() {
      if (this.emailNotification) {
        this.directMessage = false;
      }
    },
    directMessage() {
      if (this.directMessage) {
        this.emailNotification = false;
      }
    }
  },
  computed: {
    ...mapGetters(['portalData', 'configData', 'user', 'isRemoteUser', 'loa', 'isPostfachServiceAllowed',
      'isPostfachReferenceRequested']),
    subject() {
      return this.$t('administrationRequest.title');
    },
    formattedDocumentTypIdList() {
      return this.documentTypIdList.map(elem => {
        return {
          text: this.$t(`serviceConfiguration.documentTypId.${elem.textKey}`),
          value: elem.value
        };
      });
    },
    attachmentScenarios() {
      const attachmentScenarios = []
      for (let i = 0; i < 5; i++) {
        attachmentScenarios.push({
          text: this.$t(`messageForm.attachmentScenarios.${i}`),
          value: i
        })
      }
      return attachmentScenarios;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) {
        return errors;
      }
      !this.$v.description.required && errors.push(this.$t(
          'formValidation.descriptionRequired'));
      !this.$v.description.maxLength && errors.push(this.$t(
          'formValidation.descriptionMaxLength'));
      return errors;
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) {
        return errors;
      }
      !this.$v.checkbox.checked && errors.push(this.$t(
          'formValidation.dataSecurityMessage'));
      return errors;
    },
    isFormInvalid() {
      return this.$v.description.$invalid
          || this.$v.checkbox.$invalid;
    }
  },
  methods: {
    ...mapActions(['setTipPath', 'setNotification']),
    closeNotificationModal() {
      this.showNotificationModal = false;
      this.$router.push('/');
    },
    submit() {
      this.$v.$touch();

      // if postfach service is not allowed, only simulates sending a message successfully
      if (!this.configData.isPostfachActivated) {
        this.contentTextKey = 'messagePostfachDisabled';
        this.showNotificationModal = true;
        return;
      } else if (!this.isPostfachReferenceRequested) {
        this.contentTextKey = 'messageMissingPostfachReference';
        this.showNotificationModal = true;
        return;
      }

      const {messagesURL} = this.configData;

      const message = {
        subject: this.subject,
        payload: this.description,
        loa: this.loa,
        properties: {
          documentTypId: this.documentTypId,
          directMessage: this.directMessage,
          emailNotification: this.emailNotification,
          processId: `${this.loa}-50-11`,
          initMessage: true,
          fakeAttachmentScenario: this.selectedAttachmentScenario
        }
      };

      axios.post(messagesURL, message, {withCredentials: true})
      .then((response) => {
        this.messageId = response.data.result.id;
        return axios.get(
            `${messagesURL}/autoreply/${this.messageId}`,
            {withCredentials: true}
        );
      })
      .then(() => {
        // autoreply triggered, message sent successfully
        const newNotification = {
          type: 'success',
          textKey: 'sendMessageSuccess',
          show: true
        };

        if (this.emailNotification) {
          newNotification.textKey = 'sendMessageEmailNotification';
        }

        this.setNotification(newNotification);
        this.$router.push('/');
      })
      .catch(() => {
        // something went wrong in the sending process
        window.scrollTo(0, 0);
        const newNotification = {
          type: 'error',
          textKey: 'sendMessageError',
          show: true
        };
        this.setNotification(newNotification);
      });
    },
    clear() {
      this.$v.$reset();
      this.documentTypId = 'message';
      this.directMessage = false;
      this.emailNotification = false;
      this.description = '';
      this.checkbox = false;
    }
  },
  mounted() {
    this.setTipPath({pageKey: this.pageKey, tipKey: 'general'});
    if (this.isRemoteUser) {
      this.directMessage = true;
    }
  }
};
</script>
