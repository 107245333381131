<template>
  <v-sheet>
    <v-divider class="mx-4"/>
    <v-card-text class="pa-2">
      <v-btn
          class="text-subtitle-2"
          text
          @click="toggleProperties">
        <span>{{ $t('messageModal.properties') }}</span>
        <v-icon small>{{ buttonIcon }}</v-icon>
      </v-btn>
      <v-expand-transition>
        <v-sheet
            v-show="showProperties"
            class="pl-4"
            max-width="700px">
          <v-list>
            <v-list-item
                v-for="propKey in propertyKeys"
                :key="propKey">
              <v-list-item-subtitle class="mr-2">
                {{ propKey }}:
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ messageProperties[propKey] || '-' }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-expand-transition>
    </v-card-text>
  </v-sheet>
</template>

<script>
export default {
  name: 'MessageProperties',
  props: {messageProperties: Object},
  data() {
    return {
      showProperties: false
    };
  },
  computed: {
    propertyKeys() {
      return Object.keys(this.messageProperties);
    },
    buttonIcon() {
      if (this.showProperties) {
        return 'mdi-chevron-up';
      } else {
        return 'mdi-chevron-down';
      }
    }
  },
  methods: {
    toggleProperties() {
      this.showProperties = !this.showProperties;
    }
  }
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}

.v-list-item {
  min-height: 25px;
}
</style>
