<template>
  <v-sheet class="tooltips-assistant d-flex align-end">
    <v-scale-transition origin="bottom right 0">
      <v-sheet
          v-show="showAssistant"
          :color="portalData.color.bg"
          class="text-bubble white--text text-body-2 pa-3 mr-1 mb-15"
          max-height="224px"
          max-width="308px"
          v-html="$t(tipPath, variables)"/>
    </v-scale-transition>
    <v-btn
        color="#F5F5F5"
        dark
        fab
        x-large
        @click="toggleAssistant">
      <v-img
          alt="image of paula"
          max-height="68px"
          max-width="68px"
          src="../assets/paula.png"/>
    </v-btn>
  </v-sheet>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'TooltipsAssistant',
  data() {
    return {
      showAssistant: false,
    };
  },
  computed: {
    ...mapGetters(['tipPath', 'loa', 'configData', 'portalData']),
    variables() {
      const {messages, locale} = this.$i18n;
      const {portalDisplayName, serviceAccountName} = this.configData;

      return {
        portalDisplayName,
        serviceAccountName,
        loaValue: messages[locale].loa[this.loa]
      };
    }
  },
  methods: {
    toggleAssistant() {
      this.showAssistant = !this.showAssistant;
    }
  }
};
</script>

<style scoped>
.tooltips-assistant {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  background-color: transparent;
}

.tooltips-assistant :deep(a) {
  color: white;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 0 transparent;
  transition: all 0.2s ease-in-out;
}

.tooltips-assistant :deep(a:hover) {
  color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.text-bubble {
  border-radius: 20px 20px 0;
  word-wrap: break-word;
}
</style>
