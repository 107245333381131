<template>
  <v-app-bar
      class="mb-0 px-10 flex-grow-0"
      elevation="0">
    <v-toolbar-title>
      <router-link
          :to="{name: 'Home'}"
          class="text-h6 black--text">
        {{ configData.portalDisplayName }}
      </router-link>
    </v-toolbar-title>
    <v-spacer/>
    <v-btn
        :to="{name: 'Home'}"
        class="mx-2"
        icon>
      <v-icon
          class="black--text"
          medium>
        mdi-home-variant
      </v-icon>
    </v-btn>
    <div v-if="user.loggedIn">
      <v-btn
          :to="{name: 'AdministrationRequest'}"
          class="mx-1"
          icon>
        <v-icon
            class="black--text"
            medium>
          mdi-email-edit
        </v-icon>
      </v-btn>
    </div>
    <v-btn
        v-if="showMailboxIcon"
        :to="{name: 'Mailbox'}"
        class="mx-1"
        icon>
      <v-icon
          class="black--text"
          medium>
        mdi-email
      </v-icon>
    </v-btn>
    <div>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="font-weight-regular black--text px-2 mx-1"
              text
              v-bind="attrs"
              v-on="on">
            {{ $t('header.language') }}
            <v-icon medium>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="changeLanguage('en')">
            <v-list-item-title>English</v-list-item-title>
            <flag iso="gb"/>
          </v-list-item>
          <v-list-item @click="changeLanguage('de')">
            <v-list-item-title>Deutsch</v-list-item-title>
            <flag iso="de"/>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-if="user.loggedIn">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="font-weight-regular black--text px-2"
              text
              v-bind="attrs"
              v-on="on">
            {{ user.familyName }}
            <v-icon medium>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>{{ $t('header.logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PageHeader',
  computed: {
    ...mapGetters(['user', 'configData', 'isPostfachServiceAllowed']),
    showMailboxIcon() {
      return (!this.user.loggedIn && this.configData.isPostfachActivated)
          || (this.user.loggedIn && this.isPostfachServiceAllowed);
    }
  },
  methods: {
    ...mapActions(['logout']),
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.$date.locale(lang);
      localStorage.setItem('language', lang);
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.v-btn--active::before {
  opacity: 0 !important;
}

.v-btn--active:hover::before {
  opacity: 0.12 !important;
}
</style>
