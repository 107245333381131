<template>
  <v-dialog
      :value="showMessageModal"
      width="800"
      @click:outside="closeModal"
      @keydown.esc="closeModal"
      persistent>
    <v-card class="elevation-20">
      <v-card-title
          :class="portalData.color.bg"
          class="white--text py-4">
        <span class="mr-2">{{ $t('messageModal.subject') }}:</span>
        <span class="text-wrap text-break">{{ messageSubject || "---" }}</span>
      </v-card-title>
      <message-single-view
          v-if="contentType === 'message'"
          :message="messageModalContent"/>
      <message-thread-view
          v-else-if="contentType === 'thread'"
          :thread="messageModalContent"/>
      <message-attachments
          v-if="attachments"
          :attachments="attachments"
          :messageId="messageId"/>
      <message-properties :messageProperties="messageProperties"/>
      <v-divider/>
      <v-card-actions class="justify-end">
        <v-btn
            :class="portalData.color.bg"
            class="white--text"
            @click="closeModal">
          {{ $t('messageModal.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from 'vuex';
import MessageSingleView from './MessageSingleView';
import MessageThreadView from './MessageThreadView';
import MessageAttachments from './MessageAttachments';
import MessageProperties from './MessageProperties';

export default {
  name: 'MessageModal',
  components: {MessageSingleView, MessageThreadView, MessageAttachments, MessageProperties},
  props: {activeMailboxTab: String},
  computed: {
    ...mapGetters(['portalData', 'configData', 'showMessageModal', 'messageModalContent']),
    contentType() {
      if (this.messageModalContent.id) {
        return 'message';
      } else {
        return 'thread';
      }
    },
    messageSubject() {
      if (this.contentType === 'thread') {
        return this.messageModalContent[0].subject;
      } else {
        return this.messageModalContent.subject;
      }
    },
    messageProperties() {
      if (this.contentType === 'thread') {
        return this.messageModalContent[0].properties;
      } else {
        return this.messageModalContent.properties;
      }
    },
    messageId() {
      if (this.contentType === 'thread') {
        return this.messageModalContent[0].id;
      } else {
        return this.messageModalContent.id;
      }
    },
    attachments() {
      if (this.contentType === 'thread') {
        return this.messageModalContent[1].attachments;
      } else {
        return this.messageModalContent.attachments;
      }
    },
    triggerReadNotification() {
      return this.configData.finkActorIdUPIS !== this.messageModalContent.from.receiver;
    },
    allowMarkAsRead() {
      // read = false means that the read state of the message is being tracked. read = null means it's not being tracked.
      return this.activeMailboxTab === "user" && this.messageModalContent.read === false;
    }
  },
  methods: {
    ...mapActions(['closeMessageModal']),
    async closeModal() {
      if (this.allowMarkAsRead) {
        await axios.post(`${this.configData.messagesURL}/${this.messageModalContent.id}/read`,
            null,
            {withCredentials: true})

        if (this.triggerReadNotification) {
          this.$emit('triggerReadNotification');
        } else {
          this.$emit('triggerRefresh', this.messageModalContent.id);
          this.closeMessageModal();
        }
      } else {
        this.closeMessageModal();
      }
    }
  }
};
</script>

<style scoped>
.v-btn {
  text-transform: none !important;
}
</style>
