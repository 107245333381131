<template>
  <v-footer color="white" class="justify-center text-center">
    <v-card flat>
      <v-btn
          v-for="linkKey in linkKeys"
          :key="linkKey"
          :href="$t(`footer.${linkKey}.url`)"
          class="mx-1"
          rounded
          text>
        {{ $t(`footer.${linkKey}.text`) }}
      </v-btn>
      <v-card-text class="py-1">
        <span>&copy; {{ copyrightYear }} Copyright: </span>
        <v-btn
            class="text-lowercase text-body-2"
            href="https://informationsplattform.fink.services"
            rounded
            text>
          informationsplattform.fink.services
        </v-btn>
      </v-card-text>
      <v-card-text class="text-caption py-0">Version: {{ appVersion }}</v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'PageFooter',
  data() {
    return {
      appVersion: process.env.APP_VERSION,
      copyrightYear: '2020 - ' + new Date().getFullYear(),
      linkKeys: ['legalNotice', 'dataPrivacy', 'contact', 'support']
    };
  }
};
</script>

<style>
.v-btn {
  vertical-align: inherit;
}
</style>
