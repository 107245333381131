const SET_PRIVACY = (state, privacyAccepted) => {
  state.privacyAccepted = privacyAccepted;
};
const SET_CONFIG_DATA = (state, configData) => {
  state.configData = configData;
};
const SET_PORTAL_DATA = (state, value) => {
  state.portalData = value;
};
const SET_TIP_PATH = (state, tipPath) => {
  state.tipPath = tipPath;
};
const SET_USER = (state, user) => {
  state.user = user;
};
const SET_SCENARIO = (state, scenario) => {
  state.scenario = scenario;
};
const SET_LOA = (state, loa) => {
  state.loa = loa;
};
const SET_TRANSACTION_ATTESTATION = (state, transactionAttestation) => {
  state.transactionAttestation = transactionAttestation;
};
const SET_SCENARIOS_DATA = (state, scenariosData) => {
  state.scenariosData = scenariosData;
};
const SET_ATTRIBUTE_CONFIGURATION = (state, attributeConfiguration) => {
  state.attributeConfiguration = attributeConfiguration;
};
const SET_ATTRIBUTE_REPORT = (state, attributeReport) => {
  state.attributeReport = attributeReport;
};
const SET_NOTIFICATION = (state, newNotification) => {
  state.notification = newNotification;
};
const TOGGLE_LOADING = (state, isLoading) => {
  state.isLoading = isLoading;
};
const TOGGLE_MESSAGE_MODAL = (state, showMessageModal) => {
  state.showMessageModal = showMessageModal;
};
const SET_MESSAGE_MODAL_CONTENT = (state, content) => {
  state.messageModalContent = content;
};

export default {
  SET_PRIVACY,
  SET_CONFIG_DATA,
  SET_PORTAL_DATA,
  SET_TIP_PATH,
  SET_USER,
  SET_SCENARIO,
  SET_LOA,
  SET_TRANSACTION_ATTESTATION,
  SET_SCENARIOS_DATA,
  SET_ATTRIBUTE_CONFIGURATION,
  SET_ATTRIBUTE_REPORT,
  SET_NOTIFICATION,
  TOGGLE_LOADING,
  TOGGLE_MESSAGE_MODAL,
  SET_MESSAGE_MODAL_CONTENT
};
