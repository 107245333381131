<template>
  <v-sheet
      class="my-3"
      height="45px"
      width="450px">
    <v-select
        v-model="selectedTransactionAttestation"
        :disabled="user.loggedIn"
        :items="transactionAttestationList"
        :label="$t('transactionAttestation.label')"
        dense
        outlined
        rounded/>
  </v-sheet>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';

export default {
  name: "TransactionAttestationSelection",
  computed: {
    ...mapGetters(['user', 'transactionAttestation']),
    transactionAttestationList() {
      const transactionAttestationList = ['identificationReport', 'attestationRecord', 'qualityAttributes'];

      return transactionAttestationList.map(key => {
        return {text: this.$t(`transactionAttestation.${key}`), value: key};
      });
    },
    selectedTransactionAttestation: {
      get() {
        return this.transactionAttestation;
      },
      set(newTransactionAttestation) {
        this.setTransactionAttestation(newTransactionAttestation);
      }
    }
  },
  methods: {
    ...mapActions(['setTransactionAttestation'])
  }
}
</script>
