import axios from 'axios';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';
import i18n from './i18n';
import './plugins/dayjs';
import stateData from './data/stateData.json';
import FlagIcon from 'vue-flag-icon';
import VueMeta from 'vue-meta';

Vue.use(FlagIcon);
Vue.use(VueMeta);
Vue.config.devtools = true;
Vue.config.productionTip = false;

async function run() {
  const {data: configData} = await axios.get('/static/config.json');
  const {
    portalName,
    isPostfachActivated,
    isBasisregistrierungActivated,
    isNiedrigActivated,
    isUploadActivated,
    isTransactionAttestationActivated
  } = configData;

  if (isPostfachActivated === 'true') {
    configData.isPostfachActivated = true;
  } else if (isPostfachActivated
      === 'false') {
    configData.isPostfachActivated = false;
  }

  if (isBasisregistrierungActivated
      === 'true') {
    configData.isBasisregistrierungActivated = true;
  } else if (isBasisregistrierungActivated
      === 'false') {
    configData.isBasisregistrierungActivated = false;
  }

  if (isNiedrigActivated === 'true') {
    configData.isNiedrigActivated = true;
  } else if (isNiedrigActivated === 'false') {
    configData.isNiedrigActivated = false;
  }

  if (isUploadActivated === 'true') {
    configData.isUploadActivated = true;
  } else if (isUploadActivated === 'false') {
    configData.isUploadActivated = false;
  }

  if (isTransactionAttestationActivated === 'true') {
    configData.isTransactionAttestationActivated = true;
  } else if (isTransactionAttestationActivated === 'false') {
    configData.isTransactionAttestationActivated = false;
  }

  const portalData = stateData.portalList.find(data => data.key === portalName);

  await store.dispatch('setConfigData', configData);
  await store.dispatch('setPortalData', portalData);
}

run()
.then(() => {
  new Vue({
    store,
    router,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app');
});
