<template>
  <v-snackbar
      v-if="notification.textKey"
      :color="notification.type"
      :value="notification.show"
      absolute
      app
      light
      right
      text
      timeout="-1"
      top>
    <!-- @formatter:off -->
    <span
        class="snackbar-width"
        v-html="$t(`notifications.${notification.textKey}`, emailAddress)"/> <!-- njsscan-ignore: vue_template -->
    <!-- @formatter:on -->
  </v-snackbar>
</template>

<script>
import {mapGetters} from 'vuex';
import sanitizeHtml from 'sanitize-html';

export default {
  name: 'Notification',
  computed: {
    ...mapGetters(['notification', 'user']),
    emailAddress() {
      const options = {allowedTags: ['br', 'strong', 'em'], disallowedTagsMode: 'recursiveEscape'};
      const emailAddress = sanitizeHtml(this.user.emailAddress, options);
      return {emailAddress};
    }
  }
};
</script>

<style scoped>
.snackbar-width {
  display: block;
  max-width: 400px;
}

.v-snack--absolute {
  z-index: 202
}
</style>
