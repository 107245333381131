import axios from 'axios';

const setPrivacy = ({commit}, privacyAccepted) => {
  commit('SET_PRIVACY', privacyAccepted);
};
const setConfigData = ({commit}, configData) => {
  commit('SET_CONFIG_DATA', configData);
};
const setPortalData = ({commit}, portalData) => {
  commit('SET_PORTAL_DATA', portalData);
};
const setTipPath = ({commit}, keys) => {
  commit('SET_TIP_PATH', `tips.${keys.pageKey}.${keys.tipKey}`);
};
const setMouseEnterTipPath = ({commit}, e) => {
  commit(
      'SET_TIP_PATH',
      `tips.${e.target.getAttribute('data-page')}.${e.target.getAttribute(
          'data-tip-key')}`
  );
};
const authenticate = async ({state, commit, dispatch}, authData) => {
  const {loaKey, transactionAttestation, scenario, redirectURL} = authData;
  const {
    configureAttributesURL,
    userAuthenticateURL,
    isTransactionAttestationActivated
  } = state.configData;

  commit('SET_LOA', loaKey);
  commit('SET_SCENARIO', scenario);

  // remove the non-requested attributes
  const attributeConfiguration = state.attributeConfiguration.filter(
      attribute => attribute.requested);

  const authenticateURL = new URL(userAuthenticateURL);
  authenticateURL.searchParams.append('loa', loaKey);
  authenticateURL.searchParams.append('scenario', scenario);
  authenticateURL.searchParams.append('RelayState', redirectURL);
  if (isTransactionAttestationActivated) {
    authenticateURL.searchParams.append('transactionAttestation',
        transactionAttestation);
  }

  if (scenario === 'customized-attributes') {
    try {
      await axios.post(configureAttributesURL,
          {scenarioCode: scenario, attributeList: attributeConfiguration},
          {withCredentials: true});
      commit('SET_ATTRIBUTE_CONFIGURATION', attributeConfiguration);
      window.location.assign(authenticateURL.toString());
    } catch {
      window.scroll(0, 0);
      const newNotification = {
        type: 'error',
        textKey: 'authenticateError',
        show: true
      };
      dispatch('setNotification', newNotification);
    }
  } else {
    commit('SET_ATTRIBUTE_CONFIGURATION', attributeConfiguration);
    window.location.assign(authenticateURL.toString());
  }
};
const fetchUserInfo = async ({commit, state}) => {
  const {
    userInfoURL,
    isBasisregistrierungActivated,
    isNiedrigActivated,
    isTransactionAttestationActivated
  } = state.configData;

  commit('TOGGLE_LOADING', true);
  try {
    const response = await axios.get(userInfoURL,
        {withCredentials: true, timeout: 2000});
    commit('SET_USER', response.data);
  } catch {
    commit('SET_USER', {loggedIn: false});
  } finally {
    commit('TOGGLE_LOADING', false);

    if (!state.user.loggedIn) {
      // reset the variable states to default values
      commit('SET_SCENARIO', 'default');

      if (isBasisregistrierungActivated) {
        commit('SET_LOA', 'basisregistrierung');
      } else if (isNiedrigActivated) {
        commit('SET_LOA', 'niedrig');
      } else {
        commit('SET_LOA', 'normal');
      }

      if (isTransactionAttestationActivated) {
        commit('SET_TRANSACTION_ATTESTATION', 'identificationReport');
      } else {
        commit('SET_TRANSACTION_ATTESTATION', '');
      }

      commit('SET_ATTRIBUTE_REPORT', []);
    }
  }
};
const setScenario = ({commit}, scenario) => {
  commit('SET_SCENARIO', scenario);
};
const setLOA = ({commit}, loa) => {
  commit('SET_LOA', loa);
};
const setTransactionAttestation = ({commit}, transactionAttestation) => {
  commit('SET_TRANSACTION_ATTESTATION', transactionAttestation);
};
const setScenariosData = ({commit}, scenariosData) => {
  commit('SET_SCENARIOS_DATA', scenariosData);
};
const setAttributeConfiguration = ({commit}, attributeConfiguration) => {
  commit('SET_ATTRIBUTE_CONFIGURATION', attributeConfiguration);
};
const setAttributeReport = ({commit}, attributeReport) => {
  commit('SET_ATTRIBUTE_REPORT', attributeReport);
};
const setNotification = ({state, commit}, newNotification) => {
  if (state.notification.timer) {
    clearTimeout(state.notification.timer);
  }
  commit('SET_NOTIFICATION', newNotification);
  state.notification.timer = setTimeout(() => {
    commit('SET_NOTIFICATION', {...state.notification, show: false});
  }, 6000);
};
const logout = ({state}) => {
  const logoutURL = new URL(state.configData.userLogoutURL);
  logoutURL.searchParams.append('RelayState', window.location.origin);
  window.location.assign(logoutURL.toString());
};
const openMessageModal = ({commit}, content) => {
  commit('SET_MESSAGE_MODAL_CONTENT', content);
  commit('TOGGLE_MESSAGE_MODAL', true);
};
const closeMessageModal = ({commit}) => {
  commit('SET_MESSAGE_MODAL_CONTENT', null);
  commit('TOGGLE_MESSAGE_MODAL', false);
};

export default {
  setPrivacy,
  setConfigData,
  setPortalData,
  setMouseEnterTipPath,
  setTipPath,
  authenticate,
  fetchUserInfo,
  setScenario,
  setLOA,
  setTransactionAttestation,
  setScenariosData,
  setAttributeConfiguration,
  setAttributeReport,
  setNotification,
  logout,
  openMessageModal,
  closeMessageModal
};
